<template>
  <b-container class="formCard mt-3 px-2 py-2" fluid>
    <b-row class="mt-2 mb-1">
      <b-col>
        <h3>
          暗碼 {{ scoreForm.target_code }}
          <b-badge v-if="scoreForm.status === 'unsubmit'" variant="danger">
            未繳交
          </b-badge>
          <b-badge
            v-else-if="scoreForm.status === 'submitted'"
            variant="primary"
          >
            已繳交
          </b-badge>
          <b-badge
            v-else-if="scoreForm.status === 'resubmit'"
            variant="warning"
          >
            需重繳
          </b-badge>
          <b-badge
            v-else-if="scoreForm.status === 'ignore'"
            variant="secondary"
          >
            已乎略
          </b-badge>
        </h3>
      </b-col>
      <b-col lg="2">
        <b-btn
          class="m-1"
          variant="danger"
          v-b-modal="`modal-submitScore-${scoreForm.id}`"
          :disabled="form_Disabled"
        >
          繳交評分
        </b-btn>
        <b-modal
          :id="`modal-submitScore-${scoreForm.id}`"
          :title="`確認繳交 ${scoreForm.target_code} 評分表`"
          :ok-disabled="!isSubmittable"
          ok-title="繳交評分"
          ok-variant="danger"
          @ok="Submit()"
          cancel-title="取消"
        >
          <p class="my-1">
            是否確認繳交評分？提醒您，繳交後仍可於比賽期限內修改內容
          </p>
          <b-row class="mt-2">
            <b-col v-if="unFilledKeyName.length > 3">
              <p class="mb-0 text-danger">
                {{ `剩餘${unFilledKeyName.length}個必填欄位` }}
              </p>
            </b-col>
            <b-col v-else-if="unFilledKeyName.length > 0">
              <p class="mb-0 text-danger">
                {{ `剩餘 [${unFilledKeyName.join(",")}] 未填` }}
              </p>
            </b-col>
            <b-col v-else-if="unFilledKeyName.length === 0">
              <p class="mb-0 text-success">
                必填項目已完整
              </p>
            </b-col>
          </b-row>
        </b-modal>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <!-- Card1 Aroma -->
      <b-col class="scoreCard d-flex flex-column py-2 px-2 m-1">
        <div class="d-flex align-items-center justify-content-between">
          <h3
            :id="'aroma_score_' + scoreForm.id"
            class="mb-0"
            style="font-size: 1.2rem;"
          >
            Aroma
          </h3>
          <p
            class="mb-0 ml-1"
            :class="scoreForm.aroma_score ? ['text-warning'] : ['text-danger']"
            style="font-size: 2rem;min-width:4rem;"
          >
            {{ scoreForm.aroma_score || "無" }}
          </p>
        </div>
        <b-form-spinbutton
          @change="$set(scoreForm, 'aroma_score', $event) && save()"
          :value="scoreForm.aroma_score"
          :disabled="form_Disabled"
          :min="scoreForm.aroma_score ? 6 : 7"
          max="10"
          step="0.25"
        ></b-form-spinbutton>
        <div class="d-flex flex-column">
          <p :id="'aroma_dry_' + scoreForm.id" class="mb-0">Dry</p>
          <div class="d-flex justify-content-center">
            <p class="mb-0 mr-1">L</p>
            <b-form-radio-group
              class="radioBtn"
              @change="save()"
              v-model="scoreForm.aroma_dry"
              :disabled="form_Disabled"
              :options="[1, 2, 3, 4, 5]"
              button-variant="outline-primary"
              size="sm"
              buttons
            >
            </b-form-radio-group>
            <p class="mb-0 ml-1">H</p>
          </div>
        </div>

        <div class="d-flex flex-column">
          <p :id="'aroma_break_' + scoreForm.id" class="mb-0">Break</p>
          <div class="d-flex justify-content-center">
            <p class="mb-0 mr-1">L</p>
            <b-form-radio-group
              class="radioBtn"
              @change="save()"
              v-model="scoreForm.aroma_break"
              :disabled="form_Disabled"
              :options="[1, 2, 3, 4, 5]"
              button-variant="outline-primary"
              size="sm"
              buttons
            ></b-form-radio-group>
            <p class="mb-0 ml-1">H</p>
          </div>
        </div>
        <div class="d-flex flex-column ">
          <b-form-tags
            @input="save()"
            :id="'aroma_pos_' + scoreForm.id"
            :key="scoreForm.id + 'aroma_pos'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.aroma_pos"
            placeholder="aroma 正面風味評價"
          ></b-form-tags>
          <b-form-tags
            @input="save()"
            :id="'aroma_neg_' + scoreForm.id"
            :key="scoreForm.id + 'aroma_neg'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.aroma_neg"
            placeholder="aroma 負面風味評價"
          ></b-form-tags>
        </div>
      </b-col>

      <!-- Card1 Flavor Aftertaste -->
      <b-col class="scoreCard d-flex flex-column py-2 px-2 m-1">
        <div class="d-flex align-items-center justify-content-between">
          <h3
            :id="'flavor_score_' + scoreForm.id"
            class="mb-0"
            style="font-size: 1.2rem;"
          >
            Flavor
          </h3>
          <p
            class="mb-0 ml-1"
            :class="scoreForm.flavor_score ? ['text-warning'] : ['text-danger']"
            style="font-size: 2rem;min-width:4rem;"
          >
            {{ scoreForm.flavor_score || "無" }}
          </p>
        </div>
        <b-form-spinbutton
          @change="$set(scoreForm, 'flavor_score', $event) && save()"
          :value="scoreForm.flavor_score"
          :disabled="form_Disabled"
          :min="scoreForm.flavor_score ? 6 : 7"
          max="10"
          step="0.25"
        ></b-form-spinbutton>

        <div class="d-flex flex-column ">
          <b-form-tags
            @input="save()"
            :id="'flavor_pos_' + scoreForm.id"
            :key="scoreForm.id + 'flavor_pos'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.flavor_pos"
            placeholder="flavor 正面風味評價"
          ></b-form-tags>
          <b-form-tags
            @input="save()"
            :id="'flavor_neg_' + scoreForm.id"
            :key="scoreForm.id + 'flavor_neg'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.flavor_neg"
            placeholder="flavor 負面風味評價"
          ></b-form-tags>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <h3
            :id="'aftertaste_score_' + scoreForm.id"
            class="mb-0"
            style="font-size: 1.2rem;"
          >
            Aftertaste
          </h3>
          <p
            class="mb-0 ml-1"
            :class="
              scoreForm.aftertaste_score ? ['text-warning'] : ['text-danger']
            "
            style="font-size: 2rem;min-width:4rem;"
          >
            {{ scoreForm.aftertaste_score || "無" }}
          </p>
        </div>
        <b-form-spinbutton
          @change="$set(scoreForm, 'aftertaste_score', $event) && save()"
          :value="scoreForm.aftertaste_score"
          :disabled="form_Disabled"
          :min="scoreForm.aftertaste_score ? 6 : 7"
          max="10"
          step="0.25"
        ></b-form-spinbutton>

        <div class="d-flex flex-column ">
          <b-form-tags
            @input="save()"
            :id="'aftertaste_pos_' + scoreForm.id"
            :key="scoreForm.id + 'aftertaste_pos'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.aftertaste_pos"
            placeholder="aftertaste 正面風味評價"
          ></b-form-tags>
          <b-form-tags
            @input="save()"
            :id="'aftertaste_neg_' + scoreForm.id"
            :key="scoreForm.id + 'aftertaste_neg'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.aftertaste_neg"
            placeholder="aftertaste 負面風味評價"
          ></b-form-tags>
        </div>
      </b-col>

      <!-- Card3 Acidity Body -->
      <b-col class="scoreCard d-flex flex-column py-2 px-2 m-1">
        <div class="d-flex align-items-center justify-content-between">
          <h3
            :id="'acidity_score_' + scoreForm.id"
            class="mb-0"
            style="font-size: 1.2rem;"
          >
            Acidity
          </h3>
          <p
            class="mb-0 ml-1"
            :class="
              scoreForm.acidity_score ? ['text-warning'] : ['text-danger']
            "
            style="font-size: 2rem;min-width:4rem;"
          >
            {{ scoreForm.acidity_score || "無" }}
          </p>
        </div>
        <b-form-spinbutton
          @change="$set(scoreForm, 'acidity_score', $event) && save()"
          :value="scoreForm.acidity_score"
          :disabled="form_Disabled"
          :min="scoreForm.acidity_score ? 6 : 7"
          max="10"
          step="0.25"
        ></b-form-spinbutton>

        <div class="d-flex flex-column ">
          <b-form-tags
            @input="save()"
            :id="'acidity_pos_' + scoreForm.id"
            :key="scoreForm.id + 'acidity_pos'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.acidity_pos"
            placeholder="acidity 正面風味評價"
          ></b-form-tags>
          <b-form-tags
            @input="save()"
            :id="'acidity_neg_' + scoreForm.id"
            :key="scoreForm.id + 'acidity_neg'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.acidity_neg"
            placeholder="acidity 負面風味評價"
          ></b-form-tags>
        </div>

        <div class="d-flex flex-column">
          <p :id="'acidity_intensity_' + scoreForm.id" class="mb-0">
            Intensity
          </p>
          <div class="d-flex justify-content-center">
            <p class="mb-0 mr-1">L</p>
            <b-form-radio-group
              class="radioBtn"
              @change="save()"
              :disabled="form_Disabled"
              v-model="scoreForm.acidity_intensity"
              :options="[1, 2, 3, 4, 5]"
              button-variant="outline-primary"
              size="sm"
              buttons
            ></b-form-radio-group>
            <p class="mb-0 ml-1">H</p>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <h3
            :id="'body_score_' + scoreForm.id"
            class="mb-0"
            style="font-size: 1.2rem;"
          >
            Body
          </h3>
          <p
            class="mb-0 ml-1"
            :class="scoreForm.body_score ? ['text-warning'] : ['text-danger']"
            style="font-size: 2rem;min-width:4rem;"
          >
            {{ scoreForm.body_score || "無" }}
          </p>
        </div>
        <b-form-spinbutton
          @change="$set(scoreForm, 'body_score', $event) && save()"
          :value="scoreForm.body_score"
          :disabled="form_Disabled"
          :min="scoreForm.body_score ? 6 : 7"
          max="10"
          step="0.25"
        ></b-form-spinbutton>

        <div class="d-flex flex-column ">
          <b-form-tags
            @input="save()"
            :id="'body_pos_' + scoreForm.id"
            :key="scoreForm.id + 'body_pos'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.body_pos"
            placeholder="body 正面風味評價"
          ></b-form-tags>
          <b-form-tags
            @input="save()"
            :id="'body_neg_' + scoreForm.id"
            :key="scoreForm.id + 'body_neg'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.body_neg"
            placeholder="body 負面風味評價"
          ></b-form-tags>
        </div>

        <div class="d-flex flex-column">
          <p :id="'body_level_' + scoreForm.id" class="mb-0">level</p>
          <div class="d-flex justify-content-center">
            <p class="mb-0 mr-1">L</p>
            <b-form-radio-group
              class="radioBtn"
              @change="save()"
              :disabled="form_Disabled"
              v-model="scoreForm.body_level"
              :options="[1, 2, 3, 4, 5]"
              button-variant="outline-primary"
              size="sm"
              buttons
            ></b-form-radio-group>
            <p class="mb-0 ml-1">H</p>
          </div>
        </div>
      </b-col>

      <!-- Card6 cleancup sweetness -->
      <b-col class="scoreCard d-flex flex-column py-2 px-2 m-1">
        <div class="d-flex align-items-center justify-content-between">
          <h3
            :id="'uniformity_score_' + scoreForm.id"
            class="mb-0"
            style="font-size: 1.2rem;"
          >
            Uniformity
          </h3>
          <p
            class="mb-0 ml-1"
            :class="
              scoreForm.uniformity_score ? ['text-warning'] : ['text-danger']
            "
            style="font-size: 2rem;min-width:4rem;"
          >
            {{
              scoreForm.uniformity_score != null
                ? scoreForm.uniformity_score
                : "無"
            }}
          </p>
        </div>

        <div class="d-flex flex-column">
          <div class="d-flex justify-content-center">
            <b-form-rating
              :class="form_Disabled ? ['unClickable'] : []"
              icon-empty="square"
              icon-half="square-half"
              icon-full="square-fill"
              show-clear
              variant="dark"
              size="sm"
              @change="(scoreForm.uniformity_score = 10 - $event * 2) && save()"
              :value="(10 - scoreForm.uniformity_score) / 2"
            ></b-form-rating>
          </div>
        </div>

        <div class="d-flex flex-column ">
          <b-form-tags
            @input="save()"
            :id="'uniformity_pos_' + scoreForm.id"
            :key="scoreForm.id + 'uniformity_pos'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.uniformity_pos"
            placeholder="uniformity 正面風味評價"
          ></b-form-tags>
          <b-form-tags
            @input="save()"
            :id="'uniformity_neg_' + scoreForm.id"
            :key="scoreForm.id + 'uniformity_neg'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.uniformity_neg"
            placeholder="uniformity 負面風味評價"
          ></b-form-tags>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <h3
            :id="'cleancup_score_' + scoreForm.id"
            class="mb-0"
            style="font-size: 1.2rem;"
          >
            Cleancup
          </h3>
          <p
            class="mb-0 ml-1"
            :class="
              scoreForm.cleancup_score ? ['text-warning'] : ['text-danger']
            "
            style="font-size: 2rem;min-width:4rem;"
          >
            {{
              scoreForm.cleancup_score != null ? scoreForm.cleancup_score : "無"
            }}
          </p>
        </div>

        <div class="d-flex flex-column">
          <div class="d-flex justify-content-center">
            <b-form-rating
              :class="form_Disabled ? ['unClickable'] : []"
              icon-empty="square"
              icon-half="square-half"
              icon-full="square-fill"
              show-clear
              variant="dark"
              size="sm"
              @change="(scoreForm.cleancup_score = 10 - $event * 2) && save()"
              :value="(10 - scoreForm.cleancup_score) / 2"
            ></b-form-rating>
          </div>
        </div>

        <div class="d-flex flex-column ">
          <b-form-tags
            @input="save()"
            :id="'cleancup_pos_' + scoreForm.id"
            :key="scoreForm.id + 'cleancup_pos'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.cleancup_pos"
            placeholder="cleancup 正面風味評價"
          ></b-form-tags>
          <b-form-tags
            @input="save()"
            :id="'cleancup_neg_' + scoreForm.id"
            :key="scoreForm.id + 'cleancup_neg'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.cleancup_neg"
            placeholder="cleancup 負面風味評價"
          ></b-form-tags>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <h3
            :id="'sweetness_score_' + scoreForm.id"
            class="mb-0"
            style="font-size: 1.2rem;"
          >
            Sweetness
          </h3>
          <p
            class="mb-0 ml-1"
            :class="
              scoreForm.sweetness_score ? ['text-warning'] : ['text-danger']
            "
            style="font-size: 2rem;min-width:4rem;"
          >
            {{
              scoreForm.sweetness_score != null
                ? scoreForm.sweetness_score
                : "無"
            }}
          </p>
        </div>

        <div class="d-flex flex-column">
          <div class="d-flex justify-content-center">
            <b-form-rating
              :class="form_Disabled ? ['unClickable'] : []"
              icon-empty="square"
              icon-half="square-half"
              icon-full="square-fill"
              show-clear
              variant="dark"
              size="sm"
              @change="(scoreForm.sweetness_score = 10 - $event * 2) && save()"
              :value="(10 - scoreForm.sweetness_score) / 2"
            ></b-form-rating>
          </div>
        </div>

        <div class="d-flex flex-column ">
          <b-form-tags
            @input="save()"
            :id="'sweetness_pos_' + scoreForm.id"
            :key="scoreForm.id + 'sweetness_pos'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.sweetness_pos"
            placeholder="sweetness 正面風味評價"
          ></b-form-tags>
          <b-form-tags
            @input="save()"
            :id="'sweetness_neg_' + scoreForm.id"
            :key="scoreForm.id + 'sweetness_neg'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.sweetness_neg"
            placeholder="sweetness 負面風味評價"
          ></b-form-tags>
        </div>
      </b-col>

      <!-- Card7 overall -->
      <b-col class="scoreCard d-flex flex-column py-2 px-2 m-1">
        <div class="d-flex align-items-center justify-content-between">
          <h3
            :id="'balance_score_' + scoreForm.id"
            class="mb-0"
            style="font-size: 1.2rem;"
          >
            Balance
          </h3>
          <p
            class="mb-0 ml-1"
            :class="
              scoreForm.balance_score ? ['text-warning'] : ['text-danger']
            "
            style="font-size: 2rem;min-width:4rem;"
          >
            {{ scoreForm.balance_score || "無" }}
          </p>
        </div>

        <b-form-spinbutton
          @change="$set(scoreForm, 'balance_score', $event) && save()"
          :value="scoreForm.balance_score"
          :disabled="form_Disabled"
          :min="scoreForm.balance_score ? 6 : 7"
          max="10"
          step="0.25"
        ></b-form-spinbutton>

        <div class="d-flex flex-column ">
          <b-form-tags
            @input="save()"
            :id="'balance_pos_' + scoreForm.id"
            :key="scoreForm.id + 'balance_pos'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.balance_pos"
            placeholder="balance 正面風味評價"
          ></b-form-tags>
          <b-form-tags
            @input="save()"
            :id="'balance_neg_' + scoreForm.id"
            :key="scoreForm.id + 'balance_neg'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.balance_neg"
            placeholder="balance 負面風味評價"
          ></b-form-tags>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <h3
            :id="'overall_score_' + scoreForm.id"
            class="mb-0"
            style="font-size: 1.2rem;"
          >
            OverAll
          </h3>
          <p
            class="mb-0 ml-1"
            :class="
              scoreForm.overall_score ? ['text-warning'] : ['text-danger']
            "
            style="font-size: 2rem;min-width:4rem;"
          >
            {{ scoreForm.overall_score || "無" }}
          </p>
        </div>

        <b-form-spinbutton
          @change="$set(scoreForm, 'overall_score', $event) && save()"
          :value="scoreForm.overall_score"
          :disabled="form_Disabled"
          :min="scoreForm.overall_score ? 6 : 7"
          max="10"
          step="0.25"
        ></b-form-spinbutton>

        <div class="d-flex flex-column ">
          <b-form-tags
            @input="save()"
            :id="'overall_pos_' + scoreForm.id"
            :key="scoreForm.id + 'overall_pos'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.overall_pos"
            placeholder="overall 正面風味評價"
          ></b-form-tags>
          <b-form-tags
            @input="save()"
            :id="'overall_neg_' + scoreForm.id"
            :key="scoreForm.id + 'overall_neg'"
            :disabled="form_Disabled"
            class="my-1"
            size="sm"
            v-model="scoreForm.overall_neg"
            placeholder="overall 負面風味評價"
          ></b-form-tags>
        </div>
      </b-col>

      <b-col class="scoreCard d-flex flex-column py-2 px-2 m-1">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex flex-column">
            <h3
              :id="'defect_' + scoreForm.id"
              class="mb-0"
              style="font-size: 1.2rem;"
            >
              Defects
            </h3>
            <p class="mb-0">(subtract)</p>
          </div>
          <p
            class="mb-0 ml-1 text-danger"
            style="font-size: 2rem;min-width:4rem;"
          >
            {{ scoreForm.taint_cups * 2 + scoreForm.fault_cups * 4 }}
          </p>
        </div>

        <p :id="'taint_cups_' + scoreForm.id" class="mb-0 mt-2">
          Taint Cups
        </p>
        <b-form-spinbutton
          @change="save()"
          class="my-1"
          :disabled="form_Disabled"
          v-model="scoreForm.taint_cups"
          min="0"
          max="5"
        ></b-form-spinbutton>

        <p :id="'fault_cups_' + scoreForm.id" class="mb-0 mt-2">
          Fault Cups
        </p>
        <b-form-spinbutton
          @change="save()"
          class="my-1"
          :disabled="form_Disabled"
          v-model="scoreForm.fault_cups"
          min="0"
          max="5"
        ></b-form-spinbutton>
      </b-col>

      <!-- Card8 TotalScore -->
      <b-col
        class="scoreCard d-flex flex-column justify-content-center py-2 px-2 m-1"
      >
        <div class="d-flex align-items-center justify-content-center">
          <h3 class="mb-0" style="font-size: 1.2rem;">Total Score</h3>
          <p
            class="mb-0 ml-1 text-warning"
            style="font-size: 2rem;min-width:4rem;"
          >
            {{ result.total != undefined ? result.total.toFixed(2) : "無" }}
          </p>
        </div>

        <div class="d-flex align-items-center justify-content-center">
          <h3 class="mb-0" style="font-size: 1.2rem;">Defects</h3>
          <p
            class="mb-0 ml-1 text-danger"
            style="font-size: 2rem;min-width:4rem;"
          >
            {{ result.defect != undefined ? result.defect.toFixed(0) : "無" }}
          </p>
        </div>

        <div class="d-flex align-items-center justify-content-center">
          <h3 class="mb-0" style="font-size: 1.2rem;">Final Score</h3>
          <p
            class="mb-0 ml-1 text-warning"
            style="font-size: 2rem;min-width:4rem;"
          >
            {{ result.final != undefined ? result.final.toFixed(2) : "無" }}
          </p>
        </div>
      </b-col>

      <!-- Card9 BackToTop -->
      <b-col
        class="scoreCard d-sm-none d-flex flex-column justify-content-center py-2 px-2 m-1"
      >
        <b-btn variant="primary" href="#top">
          回到頂端
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { onMounted, getCurrentInstance } from "@vue/composition-api";
export default {
  name: "ScoreForm",
  props: {
    eventData: {
      type: Object,
      require: true
    },
    roundData: {
      type: Object,
      require: true
    },
    scoreData: {
      type: Object,
      require: true
    }
  },
  setup(props, { emit }) {
    const target_code = props.scoreData.target_code;

    const observer = new IntersectionObserver(
      entry => {
        entry.forEach(entity => {
          if (entity.intersectionRatio > 0) {
            emit("FormApearEvent", target_code);
          }
        });
      },
      {
        rootMargin: "-49% 0px -49%",
        threshold: 0
      }
    );
    onMounted(() => {
      observer.observe(getCurrentInstance().vnode.elm);
    });

    return {};
  },
  computed: {
    result() {
      let total = Object.keys(this.scoreForm)
        .filter(key => key.includes("_score"))
        .reduce((preVal, curKey) => {
          return preVal + this.scoreForm[curKey];
        }, 0);
      let defect =
        this.scoreForm.taint_cups * 2 + this.scoreForm.fault_cups * 4;
      let final = total - defect;
      return { total, defect, final };
    },
    form_Disabled() {
      let disabledStatusList = [];

      if (this.eventData.type === "local") {
        disabledStatusList = ["submitted", "ignore"];
      } else if (this.eventData.type === "remote") {
        disabledStatusList = ["ignore"];
      }

      return (
        this.roundData.status === "done" ||
        disabledStatusList.indexOf(this.scoreForm.status) !== -1
      );
    },
    isSubmittable() {
      let scoreKeys = Object.keys(this.scoreForm).filter(key =>
        [
          "_score",
          "acidity_intensity",
          "aroma_dry",
          "aroma_break",
          "body_level"
        ].some(t => key.includes(t))
      );

      let allowStatusList = [];

      if (this.eventData.type === "local") {
        allowStatusList = ["unsubmit", "resubmit"];
      } else if (this.eventData.type === "remote") {
        allowStatusList = ["submitted", "unsubmit", "resubmit"];
      }

      return (
        scoreKeys.every(key => this.scoreForm[key] !== null) &&
        this.roundData.status === "ongoing" &&
        allowStatusList.indexOf(this.scoreForm.status) !== -1
      );
    },
    unFilledKeyName() {
      let score = this.scoreForm;
      let scoreKeys = Object.keys(score).filter(key =>
        [
          "_score",
          "acidity_intensity",
          "aroma_dry",
          "aroma_break",
          "body_level"
        ].some(t => key.includes(t))
      );
      return scoreKeys.filter(key => score[key] == null);
    }
  },
  methods: {
    save() {
      this.$emit("save", this.scoreForm);
    },
    Submit() {
      this.$emit("submit", this.scoreForm.id);
    }
  },
  data() {
    return {
      scoreForm: this.scoreData
    };
  }
};
</script>

<style lang="scss" scoped>
.scoreCard {
  min-width: 12rem;
  max-width: 22rem;
  background: #ffffff;
  box-shadow: -5px 5px 10px rgba(181, 181, 181, 0.2),
    5px -5px 10px rgba(181, 181, 181, 0.2),
    -5px -5px 10px rgba(255, 255, 255, 0.9),
    5px 5px 13px rgba(181, 181, 181, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(181, 181, 181, 0.5);
  border-radius: 12px;
}

.radioBtn ::v-deep .btn.disabled.active,
.radioBtn ::v-deep .btn:disabled.active {
  opacity: 0.9;
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.unClickable {
  pointer-events: none;
  opacity: 0.8;
}
</style>
