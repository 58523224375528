<template>
  <b-container class="pb-3" fluid>
    <b-breadcrumb class="mt-3">
      <b-breadcrumb-item :to="{ name: 'HistoryEvent' }">
        歷史評鑑列表
      </b-breadcrumb-item>
      <b-breadcrumb-item
        :to="{ name: 'EventDashboard', params: { eventID: eventID } }"
      >
        <b-icon v-if="event.type === 'remote'" icon="cloud"></b-icon>
        {{ event.name }}
        <b-badge v-if="event.status === 'ongoing'" variant="success">
          進行中
        </b-badge>
        <b-badge v-if="event.status === 'done'" variant="secondary">
          已結束
        </b-badge>
        <b-badge v-if="event.status === 'editing'" variant="info">
          編輯中
        </b-badge>
      </b-breadcrumb-item>
      <b-breadcrumb-item
        :to="{
          name: 'EventDashboard',
          params: { eventID: eventID },
          hash: `#RoundCard-${round.id}`
        }"
      >
        {{ round.name }}
        <b-badge v-if="round.status === 'ongoing'" variant="success">
          進行中
        </b-badge>
        <b-badge v-if="round.status === 'done'" variant="secondary">
          已結束
        </b-badge>
        <b-badge v-if="round.status === 'editing'" variant="info">
          編輯中
        </b-badge>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>評分頁</b-breadcrumb-item>
    </b-breadcrumb>

    <b-row class="mt-3">
      <b-col>
        <div class="d-flex flex-wrap">
          <h2>待評分豆子 :</h2>
          <div class="d-flex flex-wrap ml-1">
            <template v-for="(t, index) in targetList">
              <b-btn
                class="m-1"
                pill
                :key="index"
                :variant="getQuickBtnVariant(t)"
                :to="{
                  name: 'Scoring',
                  params: {
                    eventID: eventID,
                    roundID: roundID,
                    scoreID: t.score_id
                  }
                }"
              >
                {{ t.code }}
              </b-btn>
            </template>
          </div>
        </div>
      </b-col>
    </b-row>
    <template
      v-for="score in scores.filter(s => {
        if (scoreID === -1) {
          return true;
        }
        return s.id === scoreID;
      })"
    >
      <scoreForm
        :roundData="round"
        :scoreData="score"
        :eventData="event"
        :key="score.id + '_' + score.status"
        :id="score.target_code"
        @save="
          AlterScore($event) &&
            (scores[scores.findIndex(s => s.id === $event.id)] = $event)
        "
        @submit="SubmitScore($event)"
        @FormApearEvent="
          target_code => {
            visible_targetCode = target_code;
          }
        "
      ></scoreForm>
    </template>

    <!-- 豆號指示鍵 -->
    <b-btn
      v-if="scoreID === -1"
      id="beanNumIndicator"
      class="beanNumIndicator"
      pill
      variant="info"
    >
      {{ visible_targetCode }}
    </b-btn>
    <b-popover
      v-if="scoreID === -1"
      target="beanNumIndicator"
      triggers="click blur"
      placement="topleft"
      fallback-placement="clockwise"
    >
      <div class="d-flex flex-wrap">
        <template
          v-for="(t, index) in targetList.filter(t => t.score_id != -1)"
        >
          <b-btn
            class="m-1"
            pill
            :key="index"
            :variant="getQuickBtnVariant(t)"
            :href="`#${t.code}`"
          >
            {{ t.code }}
          </b-btn>
        </template>
      </div>
    </b-popover>
  </b-container>
</template>

<script>
import { API } from "../store/api";
import scoreForm from "../components/ScoreForm";
export default {
  name: "Scoring",
  components: { scoreForm },
  props: {
    eventID: {
      type: Number,
      require: true
    },
    roundID: {
      type: Number,
      require: false
    },
    scoreID: {
      type: Number,
      require: false
    }
  },
  computed: {},
  data() {
    return {
      targetList: [],
      scores: [],
      event: {},
      round: {},
      visible_targetCode: ""
    };
  },
  async mounted() {
    await this.update();
  },
  watch: {
    scoreID: {
      async handler() {
        await this.update();
      }
    }
  },
  methods: {
    async update() {
      let data = await API.GetRound(this.eventID, this.roundID);
      this.event = data.event;
      this.round = data.round;

      this.scores = await API.GetMyRoundScores(this.roundID);

      this.targetList = [
        {
          code: "All",
          target_id: -1,
          score_id: -1,
          status: "unsubmit"
        }
      ].concat(
        this.scores.map(t => {
          return {
            code: t.target_code,
            target_id: t.target_id,
            score_id: t.id,
            status: t.status
          };
        })
      );

      if (this.scores.length > 0) {
        if (this.scoreID === -1) {
          this.visible_targetCode = this.scores[0].target_code;
        } else {
          this.visible_targetCode = this.scores.find(
            s => s.id === this.scoreID
          ).target_code;
        }
      }
    },
    getQuickBtnVariant(t) {
      if (t.score_id === this.scoreID) {
        return "warning";
      } else if (t.status === "unsubmit") {
        return "primary";
      } else if (t.status === "submitted") {
        return "secondary";
      } else if (t.status === "resubmit") {
        return "primary";
      } else if (t.status === "ignore") {
        return "secondary";
      } else {
        return "dark";
      }
    },
    AlterScore(data) {
      API.PUTScore({ ...data });
    },
    async SubmitScore(scoreID) {
      let res = await API.SubmitScore(scoreID);
      if (res.status === 201) {
        this.$bvToast.toast("分數繳交成功", {
          title: "通知",
          variant: "success",
          autoHideDelay: 2000
        });
      }
      await this.update();
    }
  }
};
</script>

<style lang="scss" scoped>
.formCard {
  background: #f9f9f9;
  box-shadow: -13px 13px 26px rgba(212, 212, 212, 0.2),
    13px -13px 26px rgba(212, 212, 212, 0.2),
    -13px -13px 26px rgba(255, 255, 255, 0.9),
    13px 13px 33px rgba(212, 212, 212, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(212, 212, 212, 0.5);
  border-radius: 25px;
}

.scoreCard {
  min-width: 12rem;
  max-width: 22rem;
  background: #ffffff;
  box-shadow: -5px 5px 10px rgba(181, 181, 181, 0.2),
    5px -5px 10px rgba(181, 181, 181, 0.2),
    -5px -5px 10px rgba(255, 255, 255, 0.9),
    5px 5px 13px rgba(181, 181, 181, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(181, 181, 181, 0.5);
  border-radius: 12px;
}

.radioBtn ::v-deep .btn.disabled.active,
.radioBtn ::v-deep .btn:disabled.active {
  opacity: 0.9;
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.unClickable {
  pointer-events: none;
  opacity: 0.8;
}

.beanNumIndicator {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  font-size: 1.2rem;

  border-radius: 50px;
  border-color: #17a3b842;
  background: #17a2b8;
  box-shadow: 5px 5px 10px #7d7d7da2, -5px -5px 10px #ffffffa6;

  &:hover {
    background: #148192;
  }
}
</style>
