var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"pb-3",attrs:{"fluid":""}},[_c('b-breadcrumb',{staticClass:"mt-3"},[_c('b-breadcrumb-item',{attrs:{"to":{ name: 'HistoryEvent' }}},[_vm._v(" 歷史評鑑列表 ")]),_c('b-breadcrumb-item',{attrs:{"to":{ name: 'EventDashboard', params: { eventID: _vm.eventID } }}},[(_vm.event.type === 'remote')?_c('b-icon',{attrs:{"icon":"cloud"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.event.name)+" "),(_vm.event.status === 'ongoing')?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" 進行中 ")]):_vm._e(),(_vm.event.status === 'done')?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" 已結束 ")]):_vm._e(),(_vm.event.status === 'editing')?_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(" 編輯中 ")]):_vm._e()],1),_c('b-breadcrumb-item',{attrs:{"to":{
        name: 'EventDashboard',
        params: { eventID: _vm.eventID },
        hash: ("#RoundCard-" + (_vm.round.id))
      }}},[_vm._v(" "+_vm._s(_vm.round.name)+" "),(_vm.round.status === 'ongoing')?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(" 進行中 ")]):_vm._e(),(_vm.round.status === 'done')?_c('b-badge',{attrs:{"variant":"secondary"}},[_vm._v(" 已結束 ")]):_vm._e(),(_vm.round.status === 'editing')?_c('b-badge',{attrs:{"variant":"info"}},[_vm._v(" 編輯中 ")]):_vm._e()],1),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("評分頁")])],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('div',{staticClass:"d-flex flex-wrap"},[_c('h2',[_vm._v("待評分豆子 :")]),_c('div',{staticClass:"d-flex flex-wrap ml-1"},[_vm._l((_vm.targetList),function(t,index){return [_c('b-btn',{key:index,staticClass:"m-1",attrs:{"pill":"","variant":_vm.getQuickBtnVariant(t),"to":{
                name: 'Scoring',
                params: {
                  eventID: _vm.eventID,
                  roundID: _vm.roundID,
                  scoreID: t.score_id
                }
              }}},[_vm._v(" "+_vm._s(t.code)+" ")])]})],2)])])],1),_vm._l((_vm.scores.filter(function (s) {
      if (_vm.scoreID === -1) {
        return true;
      }
      return s.id === _vm.scoreID;
    })),function(score){return [_c('scoreForm',{key:score.id + '_' + score.status,attrs:{"roundData":_vm.round,"scoreData":score,"eventData":_vm.event,"id":score.target_code},on:{"save":function($event){_vm.AlterScore($event) &&
          (_vm.scores[_vm.scores.findIndex(function (s) { return s.id === $event.id; })] = $event)},"submit":function($event){return _vm.SubmitScore($event)},"FormApearEvent":function (target_code) {
          _vm.visible_targetCode = target_code;
        }}})]}),(_vm.scoreID === -1)?_c('b-btn',{staticClass:"beanNumIndicator",attrs:{"id":"beanNumIndicator","pill":"","variant":"info"}},[_vm._v(" "+_vm._s(_vm.visible_targetCode)+" ")]):_vm._e(),(_vm.scoreID === -1)?_c('b-popover',{attrs:{"target":"beanNumIndicator","triggers":"click blur","placement":"topleft","fallback-placement":"clockwise"}},[_c('div',{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.targetList.filter(function (t) { return t.score_id != -1; })),function(t,index){return [_c('b-btn',{key:index,staticClass:"m-1",attrs:{"pill":"","variant":_vm.getQuickBtnVariant(t),"href":("#" + (t.code))}},[_vm._v(" "+_vm._s(t.code)+" ")])]})],2)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }